import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const AccessibilityPage = () => (
    <Layout>
        <Seo title={'Accessibility Information | Nemo Personal Finance '} description={'Our website has been developed to comply with the international web accessibility standards as part of their Web Accessibility initiative. Click here to see how.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Accessibility</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">The nemo-loans.co.uk web site has been developed to comply with the
                                international web accessibility standards issued by the World Wide Web Consortium (W3C)
                                as part of their Web Accessibility Initiative. All reasonable efforts have been made to
                                ensure that top level and main content pages conform to: W3C's "Web Content
                                Accessibility Guidelines 2.0" </p>
                        </div>
                    </div>
                    <strong>Standards this website meets for accessibility &amp; interoperability</strong> <p>XHTML 1.0
                    is the W3C's first Recommendation for the Hypertext Markup Language, XHTML. XHTML 1.0 is a
                    reformulation of HTML 4.01 in XML. XHTML has been designed to make web pages more accessible to
                    those with physical disabilities. HTML 5 is the latest version of W3C's validation and accessibility. <strong>This website validates as HTML 5.</strong></p>
                    <p>The nemo-loans.co.uk website uses Cascading Style Sheets (CSS) as a mechanism for adding
                        consistent style (e.g. fonts, colours, layout) to the web documents. The style sheet used on the
                        nemo-loans.co.uk website validates as CSS.</p><strong>Javascript</strong> <p>In some instances
                    this site uses JavaScript for displaying content and highlighting errors in form completion. If your
                    browser does not support JavaScript, the site will still function but it is recommended that you use
                    a JavaScript enabled browser to experience the full functionality of the website.</p><strong>Still
                    need help?</strong> <p>If you are still having difficulties using the website, contact us using one
                    of the methods below; e-mail: <a href="mailto:info@nemo-loans.co.uk">info@nemo-loans.co.uk</a> We
                    will respond to your query as quickly as possible.</p>

                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default AccessibilityPage;